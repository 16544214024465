.create-container {
  margin: 80px 10%;
}

.create-grid {
  justify-content: center;
  /* background-color: aqua; */
}

.project-item {
  margin: 0px 20px;
}

.category-chip {
  margin: 5px;
}

.btn-add {
  margin: 20px 10px !important;
}
