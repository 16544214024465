.main-container {
  margin: 20px;
  margin-top: 60px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.canvas {
  border: 1px solid;
  border-color: gainsboro;
}

@keyframes glow {
  0% {
    box-shadow: inset 0px 32px 4px #4422dd;
  }

  50% {
    box-shadow: inset 0px 34px 10px #4422dd;
  }

  100% {
    box-shadow: inset 0px 32px 4px #4422dd;
  }
}
