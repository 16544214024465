#root {
  height: 100vh;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}