html {
  font-size: 14px;
  color: #333;
  overflow-x: hidden;
}

.dot {
  height: 12px;
  width: 12px;
  padding: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

#fl-container {
  position: relative;
  min-height: 100vh;
  padding-bottom: 120px;
}
