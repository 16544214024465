.signup-bg {
  background-image: url('/public/assets/background_login.png');
  height: 100%;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.signup-grid-container {
  width: 30%;
  margin-left: 12%;
}

.signup-form {
  background-color: white;
  border-radius: 15px;
  box-shadow: 5px 5px 25px #3336;
  padding: 20px;
}

.you-rock {
  text-align: center;
  font-size: 20px;
  padding-bottom: 15px;
  margin-top: 0;
}

.signup-text {
  font-weight: bold;
  padding: 15px;
  text-align: center;
}

.signup-logo {
  text-align: center;
  color: #777;
  margin: 80px 0px 30px 0px;
}

.signup-logo h4 {
  color: #7a8697;
  width: auto;
}

@media (max-width: 900px) {
  .signup-grid-container {
    width: 40%;
  }
}

@media (max-width: 600px) {
  .signup-grid-container {
    width: auto;
    margin: 0rem 1rem;
  }
}
