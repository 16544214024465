.containerLayouts .layout {
  position: absolute;
  top: 0;
  left: 0;
  cursor: crosshair;
}

.containerLayouts {
  position: absolute;
  width: 100%;
  height: 100%;
}
