nav.navbar,
.navbar-default {
  padding-bottom: 5px;
  margin: 0;
  overflow: hidden;
  z-index: 5;
}

.navbar-default {
  background-image: none;
  border: none;
  border-radius: 0;
}

.logo {
  max-width: 160px;
}

.MuiPaper-elevation0 {
  z-index: 0 !important;
}
