.name-container {
  display: flex;
  min-height: 50px;
  max-height: 50px;
  align-items: center;
  margin-top: 2rem;
}

.display-name {
  margin: 0px;
}

.datasets-container {
  text-align: center;
}

.dataset-row {
  margin-bottom: 10px;
}

.empty-row {
  margin-top: 12%;
  width: 100%;
  text-align: center;
}

.dataset-col {
  padding: 10px;
}

.dataset-panel {
  min-height: 100px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 5px 5px 25px #3336;
}

.dataset-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.dataset-text {
  padding: 5px;
}

.img-text {
  font-size: 12px;
  color: #666;
}
