.timer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer-container .timer {
  display: flex;
}
.timer-container .timer p {
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
